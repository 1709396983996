import React from "react";

import Layout from "../../layout/Layout";
import Footer from "../../layout/footer/Footer";

export default function City(props) {
  return (
    <Layout>
      city
      <Footer value={2} />
    </Layout>
  );
}
