import Paper from "@material-ui/core/Paper";
import React from "react";
import Section, { SectionItem } from "../../../../common/Section";
import MngPost from "./MngPost";
import MngPostImg from "./MngPostImg";

export default function MngFeed(props) {
  return (
    <Section title={"Management Feed"}>
      <SectionItem>
        <MngPost
          post={{
            title: "Thank you!",
            text:
              "we would like to thank you for your kind contribution in our care pack initiative and remind you that the care pack box will be removed from HC at 31st of May, your cooperation will make difference.",
            imgUrl:
              "https://merobase-photos.s3-eu-west-2.amazonaws.com/5Z4Dn4BsnqhezLGDX/25.jpg",
            timeAgo: "3 hours"
          }}
        />
      </SectionItem>
      <SectionItem>
        <MngPost
          post={{
            title: "Happy National Day",
            text: "Happy national day for all our Sama Tower residents",
            imgUrl:
              "https://i2.wp.com/uaenationalday.net/wp-content/uploads/2018/02/National-Day-Greetings-2018.jpg?resize=586%2C502&ssl=1",
            timeAgo: "2 days ago"
          }}
        />
      </SectionItem>

      <SectionItem>
        <MngPost
          post={{
            title: "Health Club Circulation",
            text:
              "A gentle reminder on the below post and attached circular which have been shared/distributed earlier, after noticing undesirable way of dealing with the team in forcing them to open the door/gate",
            imgUrl:
              "https://merobase-photos.s3-eu-west-2.amazonaws.com/5Z4Dn4BsnqhezLGDX/18.jpg",
            timeAgo: "2 days ago"
          }}
        />
      </SectionItem>
      <SectionItem>
        <MngPost
          post={{
            title: "New Weighting Scale",
            text:
              "The HC Wheighning scale has been replaced with the new iHealth Lite wireless scale, with an advanced technology to track your health with charts and graphs helps to reach your goal.",
            imgUrl:
              "https://merobase-photos.s3-eu-west-2.amazonaws.com/5Z4Dn4BsnqhezLGDX/9.jpg",
            timeAgo: "1 week"
          }}
        />
      </SectionItem>
      <SectionItem>
        <MngPost
          post={{
            title: "Kids Pool Party 2019 !",
            text:
              "Music * Games * Photo booth * DoUbLe WaTeR sLiDe * Pop corn, Snow Cones and more !!!\n" +
              "3pm-6pm for the best party this year!",
            imgUrl:
              "https://merobase-photos.s3-eu-west-2.amazonaws.com/5Z4Dn4BsnqhezLGDX/postImage3.jpg",
            timeAgo: "8 days ago"
          }}
        />
      </SectionItem>
      <SectionItem>
        <MngPost
          post={{
            title: "Happy Valentine's Day",
            text: "Happy valentine day for all our Sama Tower residents",
            imgUrl:
              "https://merobase-photos.s3-eu-west-2.amazonaws.com/5Z4Dn4BsnqhezLGDX/17.jpg",
            timeAgo: "2 weeks ago"
          }}
        />
      </SectionItem>
    </Section>
  );
}
