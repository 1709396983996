import React from "react";
import Switch from "react-switch";

import MenuList from "./MenuList";

export default function PaperSheet() {
  return (
    <div
      style={{
        paddingLeft: "50px",
        paddingRight: "50px",
        position: "sticky",
        top: "0",
        marginTop: "20px"
      }}
    >
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
          boxShadow: "0 5px 10px rgba(0,0,0,.05)",
          backgroundColor: "#f9f9f9"
        }}
      >
        <MenuList />
      </div>
    </div>
  );
}
