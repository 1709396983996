import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";

import HeaderBar from "./header/HeaderBar";
import SideMenu from "./sideMenu/SideMenu";
import Drawer from "./sideMenu/Drawer";

export default function Layout(props) {
  const [isDrawerOpen, setDrawerOpen] = React.useState({ isDrawerOpen: false });
  React.useEffect(() => () => {
    // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  });
  return (
    <div>
      <HeaderBar
        title={props.title}
        backUrl={props.backUrl}
        onToggle={() => {
          setDrawerOpen(!isDrawerOpen);
        }}
      />
      <div>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={3}>
              <SideMenu />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={9}>
            {props.children}
          </Grid>
        </Grid>
      </div>
      <Drawer isOpen={isDrawerOpen} />
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.object,
  backUrl: PropTypes.string
};
