export const items = [
  {
    text:
      "Where is the best place to park for a couple of nights with a rental car near Sama (walking distance)? Basically only during the nights, not during daytime.",
    timeAgo: "2 hours ago",
    replies: 3
  },
  {
    text:
      "Hi all. I'm a kiwi and just moved in. I'm looking for an additional parking space mostly for weekends and holidays. Does anyone have one to rent?",
    timeAgo: "3 hours ago",
    replies: 2
  },
  {
    text:
      "Have a Bless evening to everyone madam/sir I am a looking partym job cleaning house in this building,am working same building sama tower as a full time maid,but I need extra income to support my financial and also have a little send to my family’s, I need partym every Friday for hourly basis 2-3 hours if anyone need for cleaning there house please 🙏don’t hesitate to message me, it’s a big help for me.THANK YOU and MAY THE LORD BLESSED us....\n",
    timeAgo: "2 days ago",
    replies: 4
  },
  {
    text:
      " Hi just moved into Sama Tower. Anyone here from UK? 🤔 Two things:\n" +
      "    1.) anyone looking for a tutor, drop me a message.\n" +
      "2) what do people do with regards to when someone is sending post to you from overseas, can mail be delivered to the appartments?",
    timeAgo: "3 days ago",
    replies: 4
  }
];
