import React from "react";
import { MdAddCircleOutline } from "react-icons/md";

import Section, { SectionItem } from "../../../common/Section";
import Layout from "../../../layout/Layout";
import ContributorFeed from "./components/ContributorItem";

import { items } from "./api";
import Paper from "@material-ui/core/Paper";

import ContributorItem from "./components/ContributorItem";
export default function ContributorsFeed(props) {
  const renderItems = items => {
    return items.map(item => {
      return (
        <SectionItem>
          <ContributorItem item={item} />
        </SectionItem>
      );
    });
  };

  return <Section title={"Top Contributors"}>{renderItems(items)}</Section>;
}
