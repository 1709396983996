import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";

export default function LibraryItem(props) {
  const item = props.item;
  return (
    <Paper
      style={{
        height: "160px",
        width: "120px",
        overflow: "hidden"
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundSize: "100% 100%",
          backgroundImage: `url('${item.coverImage}')`
        }}
      />
    </Paper>
  );
}

LibraryItem.propTypes = {
  item: PropTypes.object
};
