import React from "react";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Layout from "../../layout/Layout";
import Footer from "../../layout/footer/Footer";
import Section, { SectionItem } from "../../common/Section";
import ItemsFeed from "./buy&sell/ItemsFeed";
import RecommendationsFeed from "./recommendations/RecommendationsFeed";
import LibraryFeed from "./library/LibraryFeed";
import ContributorsFeed from "./contributors/ContributorsFeed";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { MdGroupAdd } from "react-icons/md";

import CT from "./CT";

export default function Community(props) {
  return (
    <Layout>
      <ItemsFeed />
      <RecommendationsFeed />
      <LibraryFeed />
      <ContributorsFeed />
      <div
        style={{
          textAlign: "center",
          borderTop: "1px solid #e0e0e0",
          padding: "10px",
          marginLeft: "20px",
          marginRight: "20px"
        }}
      >
        <div
          style={{
            fontSize: "18px",
            marginTop: "10px",
            color: "#5d5d5d"
          }}
        >
          Invite others to join Sama tower and earn <CT value={25} /> Community
          Tokens!
        </div>
        <div style={{ marginTop: "35px" }}>
          <Fab
            variant="extended"
            style={{
              width: "260px",
              backgroundColor: "rgb(83, 158, 83)",
              color: "white"
            }}
          >
            <MdGroupAdd size={35} style={{ marginRight: "5px" }} />
            Invite Someone!
          </Fab>
        </div>
        <div />
        <div
          style={{
            marginTop: "10px",
            textAlign: "center"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              paddingLeft: "40px",
              paddingRight: "40px",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          />
        </div>
      </div>

      {/*<Section title={"Interest Groups"}>Interest Groups</Section>*/}
      <Footer value={1} />
    </Layout>
  );
}
