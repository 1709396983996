import Paper from "@material-ui/core/Paper";
import React from "react";

import PropTypes from "prop-types";

export default function MngPost(props) {
  const { post } = props;
  return (
    <Paper
      style={{
        height: "170px",
        width: "300px",
        padding: "10px",
        backgroundColor: "rgb(245, 245, 245)",
        position: "relative"
      }}
    >
      <div
        style={{
          whiteSpace: "normal",
          width: "60%",
          display: "inline-block"
        }}
      >
        <div
          className={"line-clamp"}
          style={{
            height: "auto",
            fontSize: "18px",
            fontWeight: "400",
            marginBottom: "10px"
          }}
        >
          {post.title}
        </div>
        <div
          className={"line-clamp"}
          style={{
            whiteSpace: "pre-line",
            color: "gray"
          }}
        >
          {post.text}
        </div>
      </div>
      <img
        style={{
          float: "right",
          width: "90px",
          height: "90px",
          borderRadius: "10px"
        }}
        src={post.imgUrl}
      />

      <div
        style={{
          position: "absolute",
          bottom: "8px"
        }}
      >
        <img
          style={{
            display: "inline-block",
            width: "30px",
            height: "30px",
            borderRadius: "100%"
          }}
          src={"http://alainholding.ae/assets/images/sama07.jpg"}
        />
        <div
          style={{
            display: "inline-block",
            fontSize: "12px",
            position: "relative",
            top: "-10px",
            left: "9px",
            color: "#565656"
          }}
        >
          Sama Tower Management - {post.timeAgo}
        </div>
      </div>
    </Paper>
  );
}

MngPost.propTypes = {
  post: PropTypes.object
};
