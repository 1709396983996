import React from "react";
import PropTypes from "prop-types";

import Footer from "../../layout/footer/Footer";
import Layout from "../../layout/Layout";
import WelcomeCard from "./WelcomeCard";
import MngFeed from "../management/components/feed/MngFeed";
import MngMenu from "../management/components/menu/MngMenu";

export default function Home() {
  return (
    <Layout>
      <div style={{ marginTop: "22px" }}>
        <WelcomeCard />
      </div>
      <div>
        <MngFeed />
        <MngMenu />
        <Footer value={0} />
      </div>
    </Layout>
  );
}
