import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";

import CT from "../../CT";

export default function ContributorItem(props) {
  const item = props.item;
  return (
    <Paper
      style={{
        height: "180px",
        width: "165px",
        overflow: "hidden",
        textAlign: "center"
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginTop: "10px"
        }}
      >
        <img
          src={item.avatar}
          style={{
            width: "80px",
            borderRadius: "50px"
          }}
        />
      </div>
      <div style={{}}>{item.fullName}</div>
      <div
        style={{
          marginTop: "5px"
        }}
      >
        <div style={{ fontWeight: "bold", marginTop: "15px" }}>
          {item.points}
        </div>
        <div
          style={{
            height: "175px",
            width: "145px",
            color: "gray"
          }}
        >
          Community Tokens
        </div>
      </div>
    </Paper>
  );
}

ContributorItem.propTypes = {
  item: PropTypes.object
};
