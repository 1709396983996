import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

export default function SellItem(props) {
  const item = props.item;
  return (
    <Paper
      style={{
        height: "160px",
        width: "360px",
        overflow: "hidden"
      }}
    >
      <Grid
        container
        spacing={5}
        style={{
          padding: "5px"
        }}
      >
        <Grid item xs={5}>
          <img
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "5px"
            }}
            src={item.imgUrl}
          />
        </Grid>
        <Grid item xs={7}>
          <div
            className={"line-clamp"}
            style={{
              fontSize: "16px",
              fontWeight: "400",
              whiteSpace: "pre-line"
            }}
          >
            {item.description}
          </div>
          <div
            style={{
              marginTop: "30px",
              fontSize: "18px",
              fontWeight: "600"
            }}
          >
            AED {item.price}
          </div>
          <div
            style={{
              color: "gray",
              fontSize: "12px"
            }}
          >
            {item.category} - {item.timeAgo}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

SellItem.propTypes = {
  item: PropTypes.object
};
