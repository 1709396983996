import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";

import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import theme from "./theme";
import rtl from "jss-rtl";
import { create } from "jss";

import { StylesProvider, jssPreset } from "@material-ui/core/styles";

const isRtl = false;
// const jss = create({ plugins: [...jssPreset().plugins, isRtl ? rtl() : null] });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    {/*<StylesProvider jss={jss}>*/}
    <App />
    {/*</StylesProvider>*/}
  </ThemeProvider>,
  document.querySelector("#root")
);
