import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";

import { useHistory } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { GiModernCity } from "react-icons/gi";
import { IoIosPeople } from "react-icons/io";

import Hidden from "@material-ui/core/Hidden";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position: "fixed",
    width: "100%",
    bottom: "0px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 8px",
    zIndex: 3
  }
});

export default function FooterNav(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Hidden smUp>
      <div
        style={{
          height: "100px"
        }}
      />
      <BottomNavigation
        value={props.value}
        onChange={(event, newValue) => {
          if (newValue === 0) {
            history.push("/");
          } else if (newValue === 1) {
            history.push("/community");
          } else if (newValue === 2) {
            history.push("/city");
          }

          setValue(newValue);
          console.log(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          label="Home"
          icon={<GoHome size={20} />}
          onClick={e => () => {
            history.push("/city");
          }}
        />
        <BottomNavigationAction
          label="Community"
          icon={<IoIosPeople size={20} />}
          onClick={e => () => {
            history.push("/community");
          }}
        />
        {/*<BottomNavigationAction*/}
        {/*  label="City"*/}
        {/*  onClick={e => () => {*/}
        {/*    history.push("/city");*/}
        {/*  }}*/}
        {/*  icon={<GiModernCity size={20} />}*/}
        {/*/>*/}
      </BottomNavigation>
    </Hidden>
  );
}

FooterNav.propTypes = {
  value: PropTypes.number
};

FooterNav.defaultProps = {
  value: 0
};
