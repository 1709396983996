import React from "react";
import Section, { SectionItem } from "../../../common/Section";
import Layout from "../../../layout/Layout";
import SellItem from "./components/SelItem";
import { items } from "./api";

import Paper from "@material-ui/core/Paper";

import { MdAddCircleOutline } from "react-icons/md";
import SellDialog from "./components/SellDialog";
import CT from "../CT";

export default function ItemsFeed(props) {
  const [state, setState] = React.useState({
    dialogOpen: false
  });
  const renderItems = items => {
    return items.map(item => {
      return (
        <SectionItem>
          <SellItem item={item} />
        </SectionItem>
      );
    });
  };

  return (
    <Section title={"Buy & Sell"}>
      <SectionItem>
        <Paper
          onClick={() => {
            setState({
              dialogOpen: true
            });
          }}
          style={{
            color: "#525252",
            position: "relative",
            width: "120px",
            height: "160px",
            cursor: "pointer"
          }}
        >
          <div
            style={{
              position: "relative",
              top: "34px",
              textAlign: "center"
            }}
          >
            <MdAddCircleOutline size={40} />
            <div
              style={{
                width: "100%",
                fontSize: "18px"
              }}
            >
              Sell an Item
            </div>
            <div style={{ marginTop: "10px" }}>
              <CT value={5} /> Tokens
            </div>
          </div>
        </Paper>
      </SectionItem>

      {renderItems(items)}
      <SellDialog
        open={state.dialogOpen}
        onClose={() =>
          setState({
            dialogOpen: false
          })
        }
      />
    </Section>
  );
}
