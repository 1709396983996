import React from "react";
import Paper from "@material-ui/core/Paper";

import PropTypes from "prop-types";

export default function MenuTile(props) {
  return (
    <Paper
      style={{
        cursor: "pointer",
        height: "160px",
        width: "160px",
        marginTop: "10px",
        backgroundColor: props.bgColor,
        color: "white",
        fontSize: "25px"
      }}
    >
      <div>
        <div style={{ fontSize: "50px", marginTop: "30px" }}>{props.icon}</div>
        <div
          style={{
            fontSize: "18px"
          }}
        >
          {props.title}
        </div>
      </div>
    </Paper>
  );
}

MenuTile.defaultProps = {
  bgColor: "#008000ab"
};
MenuTile.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  bgColor: PropTypes.string
};
