import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuList from "./MenuList";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function SwipeableTemporaryDrawer(props) {
  const classes = useStyles();
  const isFirstRun = React.useRef(true);

  const [state, setState] = React.useState({
    left: false
  });

  React.useEffect(
    () => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
      setState({
        left: !state.left
      });
    },
    [props.isOpen]
  );

  const toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  return (
    <div>
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <MenuList />
      </SwipeableDrawer>
    </div>
  );
}
