export const items = [
  {
    imgUrl:
      "https://merobase-photos.s3-eu-west-2.amazonaws.com/itdBotbXHDjcwh3g8/28.jpg",
    description:
      "For sale, coffee tables, 3 months old. Only 100aed, perfect condition.",
    price: 300,
    timeAgo: "2 hours ago",
    category: "Furniture"
  },
  {
    imgUrl:
      "https://merobase-photos.s3-eu-west-2.amazonaws.com/itdBotbXHDjcwh3g8/33.jpg",
    description:
      "TV set and coffee table from Marina Furniture. Flawless. 1500 both.",
    price: 1500,
    timeAgo: "4 hours ago",
    category: "Furniture"
  },
  {
    imgUrl:
      "https://merobase-photos.s3-eu-west-2.amazonaws.com/itdBotbXHDjcwh3g8/30.jpg",
    description: "Living room dining table.",
    price: 1100,
    timeAgo: "2 days ago",
    category: "Furniture"
  },
  {
    imgUrl:
      "https://merobase-photos.s3-eu-west-2.amazonaws.com/itdBotbXHDjcwh3g8/29.jpg",
    description: "TV table, in great condition",
    price: 400,
    timeAgo: "2 days ago",
    category: "Furniture"
  },
  {
    imgUrl:
      "https://merobase-photos.s3-eu-west-2.amazonaws.com/itdBotbXHDjcwh3g8/25.jpg",
    description: "Washing machine Panasonic 7kg - 2 years old",
    price: 500,
    timeAgo: "3 days ago",
    category: "Appliances"
  }
];
