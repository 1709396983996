import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { IoMdClose } from "react-icons/io";
import Hidden from "@material-ui/core/Hidden";

export default function WelcomeCard(props) {
  return (
    <Paper
      style={{
        margin: "10px",
        padding: "10px",
        overflow: "hidden"
      }}
    >
      <Grid
        container
        spacing={1}
        style={{ padding: "10px", marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid container item xs={4}>
          <img
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "20px"
            }}
            src="http://alainholding.ae/assets/images/sama07.jpg"
          />
        </Grid>
        <Grid container item xs={8} style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              right: "0",
              fontSize: "20px",
              cursor: "pointer"
            }}
          >
            <IoMdClose />
          </div>
          <div
            style={{
              fontSize: "25px",
              marginLeft: "20px",
              fontWeight: "100"
            }}
          >
            Welcome to <span style={{ fontWeight: "bold" }}>Sama Tower</span>{" "}
            residence hub
          </div>
          <div
            style={{
              color: "gray",
              fontSize: "15px",
              marginTop: "10px",
              marginLeft: "20px",
              fontWeight: "100"
            }}
          >
            Connect to your building management, community and city.
          </div>
          <Hidden smUp>
            <img
              style={{
                position: "absolute",
                zIndex: "0",
                bottom: "-57PX",
                left: "-185PX",
                width: "224PX",
                transform: "rotate(30deg)"
              }}
              src={
                "https://www.timeandthevalleysmuseum.org/wp-content/uploads/2017/03/volunteer_hands.jpg"
              }
            />
          </Hidden>
        </Grid>
      </Grid>
    </Paper>
  );
}
