import React from "react";
import PropTypes from "prop-types";

export default function CT(props) {
  return (
    <div
      style={{
        display: "inline-block",
        padding: "2px",
        borderRadius: "100%",
        width: "29px",
        color: "black",
        fontSize: "16px",
        fontWeight: "700",
        backgroundColor: "#9260b124",
        border: "1px gray dashed",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
      }}
    >
      <div>{props.value}</div>
    </div>
  );
}

CT.proptTypes = {
  value: PropTypes.number
};
