import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";

import logo from "../../assets/logo.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

export default function MenuAppBar(props) {
  const classes = useStyles();
  const history = useHistory();

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = event => {
    setAuth(event.target.checked);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          background: "#404040",
          color: "white",
          boxShadow: "0 1px 8px rgba(0,0,0,.3)"
        }}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <div style={{ fontSize: "25px" }}>
              {/*<img src={logo} />*/}
              {props.backUrl && (
                <IconButton
                  onClick={() => {
                    history.push(props.backUrl);
                  }}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <MdArrowBack />
                </IconButton>
              )}
              {props.title}
              {!props.title && (
                <>
                  <div
                    style={{
                      fontSize: "35px",
                      position: "absolute",
                      top: "5px",
                      left: "15px"
                    }}
                  >
                    <IoIosPeople />
                  </div>
                  <span
                    style={{
                      fontWeight: 20,
                      marginLeft: "50px"
                    }}
                  >
                    Residence
                  </span>
                  <span
                    style={{
                      marginRight: "10px",
                      marginLeft: "4px",
                      fontWeight: "900"
                    }}
                  >
                    HUB
                  </span>
                </>
              )}
            </div>
          </Typography>
          {auth && (
            <div
              style={{
                fontSize: "35px"
              }}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                size={"large"}
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle size={35} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

MenuAppBar.propTypes = {
  onToggle: PropTypes.func,
  title: PropTypes.object,
  backUrl: PropTypes.string
};

MenuAppBar.defaultProps = {
  onToggle: () => {
    console.log("hello");
  }
};
