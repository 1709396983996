import React from "react";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Home from "./modules/home/Home";
import Community from "./modules/community/Community";
import City from "./modules/city/City";

document.documentElement.addEventListener(
  "touchmove",
  function(event) {
    event.preventDefault();
  },
  false
);

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/city">
          <City />
        </Route>
        <Route path="/community">
          <Community />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
