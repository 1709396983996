import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import faker from "faker";
import { FaRegComments } from "react-icons/fa";

export default function RecommendItem(props) {
  const item = props.item;
  return (
    <Paper
      style={{
        height: "160px",
        width: "310px",
        overflow: "hidden"
      }}
    >
      <div
        className={"line-clamp-3"}
        style={{
          width: "300px",
          padding: "20px",
          overflow: "hidden",

          whiteSpace: "pre-wrap",
          fontSize: "18px",
          fontWeight: "400",
          height: "100px"
        }}
      >
        {item.text}
      </div>
      <div
        style={{
          padding: "20px",
          position: "relative"
        }}
      >
        <img
          style={{
            width: "30px",
            borderRadius: "100%"
          }}
          src={faker.image.avatar()}
        />
        <div
          style={{
            color: "gray",
            display: "inline-block",
            position: "relative",
            top: "-11px",
            left: "8px",
            fontSize: "12px"
          }}
        >
          {faker.name.firstName()} {faker.name.lastName()} - {item.timeAgo}
        </div>
        <div
          style={{
            color: "#629ab1",
            position: "absolute",
            right: "20px",
            top: "24px"
          }}
        >
          <div
            style={{
              display: "inline-flex",
              position: "relative",
              left: "-5px",
              top: "-4px",
              fontWeight: "bold",
              fontSize: "16px"
            }}
          >
            {item.replies}
          </div>
          <FaRegComments size={22} />
        </div>
      </div>
    </Paper>
  );
}

RecommendItem.propTypes = {
  item: PropTypes.object
};
