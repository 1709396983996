import React from "react";
import Paper from "@material-ui/core/Paper";
import { FaTools } from "react-icons/fa";
import { TiFolderOpen, TiDocumentText } from "react-icons/ti";
import { MdAttachMoney, MdFeedback } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";

import Title from "../../../../common/Title";

import MenuTile from "./MenuTile";

export default function MngMenu(props) {
  return (
    <div>
      <Title title={"Management Menu"} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          textAlign: "center",
          flexWrap: "wrap",
          margin: "20px"
        }}
      >
        <MenuTile title={"Maintenance Request"} icon={<FaTools />} />

        <MenuTile
          title={"Rent Account"}
          bgColor={"rgba(32, 64, 220, 0.67)"}
          icon={<MdAttachMoney />}
        />
        <MenuTile
          title={"Documents"}
          icon={<TiFolderOpen />}
          bgColor={"rgba(114, 5, 121, 0.67)"}
        />
        <MenuTile
          title={"Surveys"}
          icon={<FiCheckCircle />}
          bgColor={"rgba(167, 138, 2, 0.67)"}
        />

        <MenuTile
          title={"Complaints & Suggestions"}
          icon={<MdFeedback />}
          bgColor={"rgba(13, 68, 55, 0.67)"}
        />
        <MenuTile
          title={"Rules & Regulations"}
          icon={<TiDocumentText />}
          bgColor={"rgba(158, 0, 44, 0.67)"}
        />
      </div>
    </div>
  );
}
