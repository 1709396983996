import React, { Component } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import { IoIosPeople } from "react-icons/io";

import { useHistory } from "react-router-dom";

import { GoHome } from "react-icons/go";
import { GiModernCity } from "react-icons/gi";

import Switch from "@material-ui/core/Switch";

export default function MenuList(props) {
  const history = useHistory();

  const iconStyle = {
    fontSize: "20px",
    marginRight: "20px"
  };
  return (
    <div role="presentation">
      <ListItem button key={"home"} onClick={() => history.push("/")}>
        <GoHome style={iconStyle} />
        <ListItemText primary={"Home"} />
      </ListItem>
      <ListItem
        button
        key={"Community"}
        onClick={() => history.push("/community")}
      >
        <IoIosPeople style={iconStyle} />
        <ListItemText primary={"Community"} />
      </ListItem>
      {/*<ListItem button key={"audits"} onClick={() => history.push("/city")}>*/}
      {/*  <GiModernCity style={iconStyle} />*/}
      {/*  <ListItemText primary={"City"} />*/}
      {/*</ListItem>*/}
    </div>
  );
}
