export const items = [
  {
    coverImage:
      "https://m.media-amazon.com/images/I/61oWOz2lTgL._AC_UY436_FMwebp_QL65_.jpg"
  },
  {
    coverImage:
      "https://images-na.ssl-images-amazon.com/images/I/41JC01G2THL._SX345_BO1,204,203,200_.jpg"
  },
  {
    coverImage:
      "https://images-na.ssl-images-amazon.com/images/I/512YAyyk-NL._SX430_BO1,204,203,200_.jpg"
  },
  {
    coverImage:
      "https://images-na.ssl-images-amazon.com/images/I/61D2koIlLUL._SX404_BO1,204,203,200_.jpg"
  }
];
