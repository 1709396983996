import React from "react";
import PropTypes from "prop-types";

import Title from "./Title";

export default function Section(props) {
  return (
    <div>
      <Title title={props.title} subtitle={props.subtitle} />
      <div
        style={{
          padding: "10px",
          height: props.height ? `${props.height}px` : "100%"
        }}
      >
        <div
          style={{
            overflowX: "scroll",
            overflowY: "hidden",
            display: "flex",
            flex: `0 0 ${props.itemsWidth}px`,
            whiteSpace: "nowrap",
            padding: "12px 1px"
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.object,
  height: PropTypes.number,
  itemsWidth: PropTypes.number
};

export const SectionItem = function(props) {
  return (
    <div
      style={{
        display: "inline-block",
        cursor: "pointer",
        marginRight: "10px"
      }}
    >
      {props.children}
    </div>
  );
};

Section.SectionItem = SectionItem;
