import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { MdCameraEnhance } from "react-icons/md";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  uploadBtnContainer: {
    textAlign: "center",
    width: "100%"
  },
  uploadBtn: {
    borderRadius: "20px",
    width: "200px",
    marginBottom: "20px"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 200
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

export default function SellDialog(props) {
  const classes = useStyles();

  const { open, onClose } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Sell An Item</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div className={classes.uploadBtnContainer}>
              <input
                accept="image/*"
                // className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button
                  className={classes.uploadBtn}
                  variant="outlined"
                  color="primary"
                  component="span"
                  // className={classes.button}
                >
                  <MdCameraEnhance size={20} />
                  &nbsp;Add Image
                </Button>
              </label>
            </div>
            <TextField
              id="standard-full-width"
              label="Description"
              style={{ margin: 8 }}
              fullWidth
              multiline
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              id="standard-full-width"
              label="Price"
              type="number"
              style={{ margin: 8 }}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{
            marginTop: "20px"
          }}
        >
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onClose} color="primary">
            Post
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SellDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
