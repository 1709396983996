import React from "react";
import { MdAddCircleOutline } from "react-icons/md";

import Section, { SectionItem } from "../../../common/Section";
import Layout from "../../../layout/Layout";
import LibraryItem from "./components/Librarytem";

import { items } from "./api";
import Paper from "@material-ui/core/Paper";
import CT from "../CT";

export default function LibraryFeed(props) {
  const renderItems = items => {
    return items.map(item => {
      return (
        <SectionItem>
          <LibraryItem item={item} />
        </SectionItem>
      );
    });
  };

  return (
    <Section title={"Community library"}>
      <SectionItem>
        <Paper
          onClick={() => {}}
          style={{
            color: "#525252",
            position: "relative",
            width: "120px",
            height: "160px",
            cursor: "pointer"
          }}
        >
          <div
            style={{
              position: "relative",
              top: "34px",
              textAlign: "center"
            }}
          >
            <MdAddCircleOutline size={40} />
            <div
              style={{
                width: "100%",
                whiteSpace: "pre-wrap",
                fontSize: "18px"
              }}
            >
              Add A Book
            </div>
            <div style={{ marginTop: "10px" }}>
              <CT value={5} /> Tokens
            </div>
          </div>
        </Paper>
      </SectionItem>
      {renderItems(items)}
    </Section>
  );
}
